<script setup lang="ts">
defineSlice({
  name: { label: 'Bento Lightning Bolt Brand', group: 'Bento', layouts: ['VoixMarketing'] },
  description: '',
  preview: 'SlicesBentoLightningBoltBrand.jpg',
  fields: {

  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const alternateColors = [
  { markBottomFill: '#EA3F32', markMiddleFill: '#3805F5', markTopStroke: '#FFFD5A' }, // Default
  { markBottomFill: '#bc6c25', markMiddleFill: '#606c38', markTopStroke: '#fefae0' },
  { markBottomFill: '#a2d2ff', markMiddleFill: '#ffafcc', markTopStroke: '#bde0fe' },
  { markBottomFill: '#fb8500', markMiddleFill: '#219ebc', markTopStroke: '#ffb703' },
  { markBottomFill: '#e63946', markMiddleFill: '#457b9d', markTopStroke: '#f1faee' },
  { markBottomFill: '#faedcd', markMiddleFill: '#d4a373', markTopStroke: '#ccd5ae' },
  { markBottomFill: '#8338ec', markMiddleFill: '#ff006e', markTopStroke: '#3a86ff' },
  { markBottomFill: '#06d6a0', markMiddleFill: '#ef476f', markTopStroke: '#ffd166' },
  { markBottomFill: '#53d8fb', markMiddleFill: '#399e5a', markTopStroke: '#fb9f89' },
  { markBottomFill: '#5f4bb6', markMiddleFill: '#86a5d9', markTopStroke: '#eff6e0' },
]

const finalColors = ref({
  markBottomFill: '#EA3F32',
  markMiddleFill: '#3805F5',
  markTopStroke: '#FFFD5A',
})
function selectColors(colors: { markBottomFill: string, markMiddleFill: string, markTopStroke: string }) {
  finalColors.value = colors
}

const activated = inject('activated')
</script>

<template>
  <div class="absolute inset-0 overflow-hidden group flex justify-center items-center">
    <div class="grid grid-cols-2 gap-4 -translate-y-[20%] duration-1000 delay-200" :class="{ 'grayscale opacity-20': !activated }">
      <div class="grid grid-cols-2 gap-4 pl-8">
        <button v-for="colors in alternateColors" :key="colors.markBottomFill" class="flex" @click="selectColors(colors)">
          <div class="w-4 h-4 rounded-full flex-none" :style="{ backgroundColor: colors.markBottomFill }" />
          <div class="w-4 h-4 rounded-full flex-none -translate-x-1/3" :style="{ backgroundColor: colors.markMiddleFill }" />
          <div class="w-4 h-4 rounded-full flex-none -translate-x-2/3" :style="{ backgroundColor: colors.markTopStroke }" />
        </button>
      </div>
      <div class="relative flex justify-center items-center">
        <div class="absolute z-0 inset-0 flex justify-center items-center">
          <svg
            class="translate-x-10 w-72 h-72 blur-3xl duration-500 delay-700"
            viewBox="0 0 93 93" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve" xmlns:serif="http://www.serif.com/"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:2;"
          >
            <g>
              <path
                class=" animate-pulse"
                d="M47.58,30.273c0,0 -9.469,2.017 -8.68,-5.523l3.42,-18.063c-0,-0 0.525,-2.017 -1.579,-2.806c-0,0 -1.929,-0.701 -3.069,1.14c-1.14,1.841 -19.991,37.703 -19.991,37.703c0,-0 -0.964,2.718 0.789,3.946c0,-0 0.877,1.051 2.718,0.613l4.823,-1.14c0,0 2.893,-0.789 4.734,1.578c0,0 1.491,1.141 0.965,4.297l-6.05,22.533c0,0 -0.701,1.842 1.403,2.806c-0,0 1.403,0.438 2.718,-1.228l20.956,-41.121c0,-0 1.052,-1.929 -0.614,-3.771c0,0 -1.315,-1.051 -2.367,-0.964l-0.176,0Z"
                style="fill-rule:nonzero;stroke-width:7.5px;"
                :style="{ fill: finalColors.markBottomFill }"
              />
              <path
                class="translate-x-4 -translate-y-4 animate-pulse"
                d="M37.585,9.668l-3.42,18.062c-0.526,2.982 1.754,5.7 4.822,5.612l3.858,-0c2.456,-0.176 4.121,2.367 2.982,4.559l-20.956,41.122c-1.227,2.368 -4.823,0.964 -4.121,-1.579l6.05,-22.532c0.965,-3.508 -2.192,-6.664 -5.699,-5.875l-4.823,1.14c-2.63,0.614 -4.734,-2.192 -3.507,-4.56l19.991,-37.702c1.316,-2.543 5.174,-1.14 4.647,1.666l0.176,-0l-0,0.087Z"
                style="fill-rule:nonzero;stroke-width:7.5px;"
                :style="{ fill: finalColors.markMiddleFill }"
              />
              <path
                class="-translate-x-4 translate-y-4 animate-pulse"
                d="M38.549,36.498c-0,0 -9.47,2.017 -8.68,-5.524l3.419,-18.062c0,-0 0.526,-2.017 -1.578,-2.806c-0,0 -1.929,-0.701 -3.069,1.14c-1.14,1.841 -19.991,37.702 -19.991,37.702c-0,0 -0.964,2.719 0.789,3.947c0,-0 0.877,1.051 2.718,0.613l4.823,-1.14c-0,0 2.893,-0.789 4.734,1.578c0,0 1.491,1.141 0.965,4.297l-6.05,22.534c-0,-0 -0.701,1.841 1.402,2.805c0,0 1.404,0.439 2.719,-1.227l20.956,-41.122c-0,-0 1.051,-1.929 -0.614,-3.771c-0,0 -1.315,-1.051 -2.368,-0.964l-0.175,0Z"
                style="stroke-width:7.5px;stroke-linejoin:miter;"
                :style="{ stroke: finalColors.markTopStroke }"
              />
            </g>
          </svg>
        </div>

        <svg
          class="relative z-10 w-56 h-56 "
          viewBox="0 0 93 93" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          xml:space="preserve" xmlns:serif="http://www.serif.com/"
          style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:2;"
        >
          <g class="translate-x-4">
            <path
              d="M47.58,30.273c0,0 -9.469,2.017 -8.68,-5.523l3.42,-18.063c-0,-0 0.525,-2.017 -1.579,-2.806c-0,0 -1.929,-0.701 -3.069,1.14c-1.14,1.841 -19.991,37.703 -19.991,37.703c0,-0 -0.964,2.718 0.789,3.946c0,-0 0.877,1.051 2.718,0.613l4.823,-1.14c0,0 2.893,-0.789 4.734,1.578c0,0 1.491,1.141 0.965,4.297l-6.05,22.533c0,0 -0.701,1.842 1.403,2.806c-0,0 1.403,0.438 2.718,-1.228l20.956,-41.121c0,-0 1.052,-1.929 -0.614,-3.771c0,0 -1.315,-1.051 -2.367,-0.964l-0.176,0Z"
              style="fill-rule:nonzero;stroke:#000;stroke-width:7.5px;"
            />
            <path
              d="M37.585,9.668l-3.42,18.062c-0.526,2.982 1.754,5.7 4.822,5.612l3.858,-0c2.456,-0.176 4.121,2.367 2.982,4.559l-20.956,41.122c-1.227,2.368 -4.823,0.964 -4.121,-1.579l6.05,-22.532c0.965,-3.508 -2.192,-6.664 -5.699,-5.875l-4.823,1.14c-2.63,0.614 -4.734,-2.192 -3.507,-4.56l19.991,-37.702c1.316,-2.543 5.174,-1.14 4.647,1.666l0.176,-0l-0,0.087Z"
              style="fill-rule:nonzero;stroke:#000;stroke-width:7.5px;"
            />
            <path
              d="M38.549,36.498c-0,0 -9.47,2.017 -8.68,-5.524l3.419,-18.062c0,-0 0.526,-2.017 -1.578,-2.806c-0,0 -1.929,-0.701 -3.069,1.14c-1.14,1.841 -19.991,37.702 -19.991,37.702c-0,0 -0.964,2.719 0.789,3.947c0,-0 0.877,1.051 2.718,0.613l4.823,-1.14c-0,0 2.893,-0.789 4.734,1.578c0,0 1.491,1.141 0.965,4.297l-6.05,22.534c-0,-0 -0.701,1.841 1.402,2.805c0,0 1.404,0.439 2.719,-1.227l20.956,-41.122c-0,-0 1.051,-1.929 -0.614,-3.771c-0,0 -1.315,-1.051 -2.368,-0.964l-0.175,0Z"
              style="stroke:#000;stroke-width:7.5px;stroke-linejoin:miter;"
            />
          </g>
          <g class="translate-x-4 duration-1000 ease-out">
            <path
              id="bottom-bolt"
              d="M47.58,30.273c0,0 -9.469,2.017 -8.68,-5.523l3.42,-18.063c-0,-0 0.525,-2.017 -1.579,-2.806c-0,0 -1.929,-0.701 -3.069,1.14c-1.14,1.841 -19.991,37.703 -19.991,37.703c0,-0 -0.964,2.718 0.789,3.946c0,-0 0.877,1.051 2.718,0.613l4.823,-1.14c0,0 2.893,-0.789 4.734,1.578c0,0 1.491,1.141 0.965,4.297l-6.05,22.533c0,0 -0.701,1.842 1.403,2.806c-0,0 1.403,0.438 2.718,-1.228l20.956,-41.121c0,-0 1.052,-1.929 -0.614,-3.771c0,0 -1.315,-1.051 -2.367,-0.964l-0.176,0Z"
              style="fill-rule:nonzero;" :style="{ fill: finalColors.markBottomFill }"
              class="duration-200 ease-in"
            />
            <path
              id="middle-bolt"
              d="M37.585,9.668l-3.42,18.062c-0.526,2.982 1.754,5.7 4.822,5.612l3.858,-0c2.456,-0.176 4.121,2.367 2.982,4.559l-20.956,41.122c-1.227,2.368 -4.823,0.964 -4.121,-1.579l6.05,-22.532c0.965,-3.508 -2.192,-6.664 -5.699,-5.875l-4.823,1.14c-2.63,0.614 -4.734,-2.192 -3.507,-4.56l19.991,-37.702c1.316,-2.543 5.174,-1.14 4.647,1.666l0.176,-0l-0,0.087Z"
              style="fill-rule:nonzero;" :style="{ fill: finalColors.markMiddleFill }"
              class="duration-200 ease-in delay-100"
            />
            <path
              id="top-bolt"
              d="M38.549,36.498c-0,0 -9.47,2.017 -8.68,-5.524l3.419,-18.062c0,-0 0.526,-2.017 -1.578,-2.806c-0,0 -1.929,-0.701 -3.069,1.14c-1.14,1.841 -19.991,37.702 -19.991,37.702c-0,0 -0.964,2.719 0.789,3.947c0,-0 0.877,1.051 2.718,0.613l4.823,-1.14c-0,0 2.893,-0.789 4.734,1.578c0,0 1.491,1.141 0.965,4.297l-6.05,22.534c-0,-0 -0.701,1.841 1.402,2.805c0,0 1.404,0.439 2.719,-1.227l20.956,-41.122c-0,-0 1.051,-1.929 -0.614,-3.771c-0,0 -1.315,-1.051 -2.368,-0.964l-0.175,0Z"
              style="fill:none;stroke-width:0.88px;stroke-linejoin:miter;" :style="{ stroke: finalColors.markTopStroke }"
              class="duration-200 ease-in delay-200"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>
